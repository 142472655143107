<template>
  <article class="container">
    <h2 class="title">{{$t('MyFavorites')}}</h2>
    <div class="flex-between-center">
      <ul class="tabs" v-if="tagList && tagList.length">
        <li
          v-for="item in tagList"
          :key="item.ID"
          :class="{ active: selectedTagID == item.ID }"
          @click="getListByTag(item.ID)"
        >
          {{ item.title }}
        </li>
      </ul>
      <p class="sort-type">
        <i
          :class="{ active: selectedTimeType == 0 }"
          @click="selectedTimeType = 0"
          >24H</i
        >
        /
        <i
          :class="{ active: selectedTimeType == 1 }"
          @click="selectedTimeType = 1"
          >7D</i
        >
      </p>
    </div>
    <main class="table-responsive">
      <table v-if="list && list.length > 0">
        <thead>
          <tr>
            <th v-for="(item,i) in thList" :key="i" v-html="$t(item)"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in list" :key="item.ID" @click="goProjectDetail(item.bscProjectID)">
            <td>
              <div class="flex-align-center">
                <img
                  :src="
                    project_oss_link +
                    item.img
                  "
                  width="32"
                  @error="logoError"
                />
                <div class="td-max-width">
                  <p class="text-ellipsis">{{ item.title }}</p>
                  <h6 class="color-grey text-ellipsis">
                    <i
                      v-for="(tag, index) in item.tags"
                      :key="index"
                      class="mr-6"
                      >{{ tag }}</i
                    >
                  </h6>
                </div>
              </div>
            </td>
            <td>{{ item.tvlShort ? item.tvlShort : "--" }}</td>
            <template v-if="item.tvlChange || item.tvlWeekChange ">
              <td v-if="selectedTimeType == 0" :class="[item.tvlChange>=0?'color-up':'color-down']">{{(webUtil.forPct(item.tvlChange)+'%')}}</td>
              <td v-else :class="[item.tvlWeekChange>=0?'color-up':'color-down']">{{(webUtil.forPct(item.tvlWeekChange)+'%')}}</td>
            </template>
            <td v-else>--</td>
            <td>{{ item.addressShort ? item.addressShort : "--" }}</td>
            <template v-if="item.addressChange || item.addressWeekChange ">
              <td v-if="selectedTimeType == 0" :class="[item.addressChange>=0?'color-up':'color-down']">{{webUtil.forPct(item.addressChange) }}%</td>
              <td v-else :class="[item.addressWeekChange>=0?'color-up':'color-down']">{{webUtil.forPct(item.addressWeekChange) }}%</td>
            </template>
            <td v-else>--</td>
            <td>{{ item.txnShort ? item.txnShort : "--" }}</td>
            <template v-if="item.txnChange || item.txnWeekChange ">
              <td v-if="selectedTimeType == 0" :class="[item.txnChange>=0?'color-up':'color-down']">{{webUtil.forPct(item.txnChange) }}%</td>
              <td v-else :class="[item.txnWeekChange>=0?'color-up':'color-down']">{{webUtil.forPct(item.txnWeekChange) }}%</td>
            </template>
            <td v-else>--</td>
            <td>{{ item.token ? item.token.symbol : "--" }}</td>
            <td v-if="item.token && item.price > 0">${{ webUtil.addCommas(item.price) }}</td>
            <td v-else>--</td>
            <template v-if="item.percentChange || item.percentChangeWeek ">
              <td v-if="selectedTimeType == 0" :class="[item.percentChange>=0?'color-up':'color-down']">{{item.percentChange }}%</td>
              <td v-else :class="[item.percentChangeWeek>=0?'color-up':'color-down']">{{item.percentChangeWeek }}%</td>
            </template>
            <td v-else>--</td>
            <td>{{item.like?item.like:'--'}}</td>
            <td>
              <button @click.stop="deleteFav(item.bscProjectID)">
                <img src="@/assets/img/icon/delete.png" width="16" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="null" v-else>{{ $t("NoInformation") }}</div>
    </main>
    <Pagination
      :total="total"
      :page="page"
      :limit="limit"
      @emitPage="getList"
    />
  </article>
</template>
<script>
import Pagination from "@/components/Pagination";
export default {
  components: { Pagination },
  data() {
    return {
      total: 0,
      limit: 10, //每页几条数据
      page: 1,
      list: null,
      tagList:null,
      selectedTagID:0,
      selectedTimeType:1,
      thList:["Project","tvl","tvlChange","address","addressChange","txn","txnChange","Token","price","percentChange","like","Operation"],
    };
  },
  created() {
    this.getTags();
  },
  methods: {
    async deleteFav(ID){
      if (!ID) {
        return false;
      }
      let signResult = await this.sign();
      const qs = require('qs');
      this.axios.post(this.domain+'bscProjectStarCancel',qs.stringify({
        address:this.account,
        sign:signResult,
        message:this.signMessageStr,
        projectID:ID
      })).then(res=>{
        if(res.data.success){
          this.getList();
        }else{
          this.$loading(0);
        }
      }).catch(err=>{
        this.$loading(0);
        console.log(err);
      })
    },
    goProjectDetail(ID) {
      if (ID) {
        this.$router.push("/project/" + ID)
      }
    },
    getList(page = 1) {
      if(!this.account){
        this.$router.push('/')
        return false;
      }
      this.page = page;
      this.$loading(1);
      this.axios
        .get(
          this.domain +
            "getMyBscStars?v=2.0&tagID="+this.selectedTagID+"&limit=" +
            this.limit +
            "&page=" +
            (this.page - 1) +
            "&address=" +
            this.account
        )
        .then((res) => {
          this.$loading(0);
          if (res.data.success) {
            this.list = res.data.data.list;
            this.total = res.data.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$loading(0);
        });
    },
    getListByTag(ID){
      this.selectedTagID = ID;
      this.getList();
    },
    getTags(){
      if(!this.account){
        this.$router.push('/')
        return false;
      }
      this.$loading(1);
      this.tagList = [{
        ID:0,
        title:this.$t('All')
      }]
      this.axios.get(this.domain+'getMyStarsTags?address='+this.account).then(res=>{
        if(res.data.success){
          let tagList = res.data.data;
          this.tagList = [...this.tagList,...tagList];
        }
        this.getList();
      }).catch(()=>{
        this.getList();
      })
    }
  },
  watch:{
    account(){
      this.getTags();
    }
  }
};
</script>
<style scoped>
button {
  border: none;
  border-radius: 50%;
  padding: 8px;
  background: #f5f5f5;
  cursor: pointer;
}
button img{
  display: block;
}
table td:first-child{
  min-width: 160px;
}

td:first-child .td-max-width {
  width: calc(100% - 48px);
}

th:nth-child(n+2),
td:nth-child(n+2) {
  text-align: right;
}

th:nth-child(n+11),td:nth-child(n+11){
  min-width: 40px;
}
</style>
